import {graphql} from "gatsby";
import React, {FC} from "react";
import EmailAddress from "../components/email-address";
import Seo from "../components/seo";
import ExtLink from "../components/ext-link";
import GdprToggleConsentButton from "../gdpr/components/gdpr-consent-button";
import {useMatomo} from "../gdpr/hooks/use-matomo";
import {matomoTrack} from "../gdpr/utils/matomo";
import {ConsentChoice, cookieConsentButton, requestConsentButton} from "../gdpr/tracking-events/cookie-consent";
import {PostalAddress} from "../types";
import Layout from "../components/layout/layout-default";

interface PrivacyPolicyPageProps {
    data: {
        site: {
            siteMetadata: {
                author: {
                    address: PostalAddress;
                };
            };
        };
    };
}

export const query = graphql`
    query {
        site {
            siteMetadata {
                author {
                    address {
                        name
                        street
                        zip
                        city
                        country
                        emailEncrypted
                        taxNumber
                        vatId
                    }
                }
            }
        }
    }
`;

const PrivacyPolicyPage: FC<PrivacyPolicyPageProps> = ({data}) => (
    <Layout>
        <Seo title="Privacy policy" description="The privacy policy of this page." />
        <section className="container mx-auto my-16 px-4 space-y-6 max-w-xl">
            <h1>{"Privacy policy"}</h1>
            <BoxGeneralInformation />
            <BoxCookies />
            <BoxMatomo />
            <BoxServerFiles />
            <BoxSsl />
            <BoxChanges />
            <BoxQuestionsComments address={data.site.siteMetadata.author.address} />
            <BoxPersonInCharge address={data.site.siteMetadata.author.address} />
            <BoxLastUpdated />
        </section>
    </Layout>
);

const BoxGeneralInformation = () => (
    <div>
        <h3>{"General information"}</h3>
        <p>
            {
                "This website website has been designed in such a way that as little personal data as possible is collected or processed. The following declaration on data protection explains what information is collected during your visit to this website and which parts of this information may be used in which way."
            }
        </p>
    </div>
);

const BoxCookies = () => (
    <div>
        <h3>{"Cookies"}</h3>
        <p>
            {
                "A cookie is a string of information that a website stores on a visitor’s device, and that the visitor’s browser provides to the website each time the visitor returns."
            }
        </p>
        <p>
            {
                "This website uses cookies to help identifying and track visitors, their usage of this website, and their website access preferences."
            }
        </p>
        <p>
            {
                "Cookies for tracking are only used with your active consent as soon as you agree to this in the cookie dialogue that is displayed at the beginning of your visit to this website."
            }
        </p>
        <p>
            {
                "Of course, you can also view this website without cookies. Internet browsers are regularly set to accept cookies. In general, you can deactivate the use of cookies at any time via the settings of your browser. Please use the help functions of your internet browser to find out how you can change these settings."
            }
        </p>
    </div>
);

const BoxMatomo = () => {
    const {cookiesEnabled, requestsEnabled, setCookiesEnabled, setRequestsEnabled} = useMatomo();

    const toggleCookieConsent = () => {
        const newConsentValue = !cookiesEnabled;
        matomoTrack(cookieConsentButton(newConsentValue ? ConsentChoice.Accept : ConsentChoice.Decline));
        setCookiesEnabled(newConsentValue);
    };

    const toggleRequestConsent = () => {
        const newConsentValue = !requestsEnabled;
        matomoTrack(requestConsentButton(newConsentValue ? ConsentChoice.Accept : ConsentChoice.Decline));
        setRequestsEnabled(newConsentValue);
    };

    return (
        <div>
            <h3>{"Use of Matomo"}</h3>
            <p>
                {
                    "Matomo is used to analyse traffic to this website. Matomo is an open source analytics alternative to Google Analytics. It is self-hosted on "
                }
                <ExtLink href="https://uberspace.de/">{"Uberspace"}</ExtLink>
                {
                    " on german servers. Therefore no data is sent to third parties. You can visit this website without having to worry about your data being used for marketing or advertising platforms."
                }
            </p>
            <p>
                {
                    "Matomo is additionally configured so that your IP address is only recorded in abbreviated form. Therefore your personal usage data is processed anonymously. It is not possible to draw conclusions about your person."
                }
            </p>
            <p>{"Your data will be deleted automatically after 12 months."}</p>
            <p>
                {
                    "By default request tracking cookies are used. This means that tracking requests are sent to Matomo without your consent, but no data will be stored on your device. Therefore you can't be tracked across websites. Fingerprints change daily for every visitor meaning no visitor can be followed across days within the same website, and no user profiles can be generated when cookies are disabled."
                }
            </p>
            <p>
                {
                    "This service is used to gain an impression of the usage behaviour of my website visitors and to optimise the website in terms of content, usability, stability and security accordingly."
                }
            </p>
            <p>
                {
                    'In addition to request tracking, I would also like to use tracking cookies, which would give more accurate tracking results. You can opt-in to cookie tracking in the "Tracking Consent" section below.'
                }
            </p>
            <p>
                {"Further information on Matomo's terms of use and data protection regulations can be found at: "}
                <ExtLink href="https://matomo.org/privacy/">{"https://matomo.org/privacy/"}</ExtLink>
            </p>
            <h4>Tracking Consent</h4>
            <p>
                <GdprToggleConsentButton
                    textLabels={[
                        "Cookie tracking is enabled. Thanks for your trust! Matomo tracking cookies are stored on your device. Click here to disable cookie tracking again.",
                        "Cookie tracking is disabled. No tracking cookies will be stored on your device.",
                    ]}
                    buttonLabels={["Deactivate cookie tracking", "Activate cookie- & request tracking"]}
                    onToggle={toggleCookieConsent}
                    enabled={cookiesEnabled}
                />
            </p>
            <p>
                <GdprToggleConsentButton
                    textLabels={[
                        "Request tracking is enabled. Click here to disable request tracking.",
                        "Request tracking is disabled. You may choose to prevent this website from storing tracking cookies. Doing so will protect your privacy, but will also prevent the owner from learning from your actions and creating a better experience for you and other users.",
                    ]}
                    buttonLabels={["Deactivate request- & cookie tracking", "Activate request tracking"]}
                    onToggle={toggleRequestConsent}
                    enabled={requestsEnabled}
                />
            </p>
        </div>
    );
};

const BoxServerFiles = () => (
    <div>
        <h3>{"Sever log files"}</h3>
        <p>{"The web server does not store any log files."}</p>
    </div>
);

const BoxSsl = () => (
    <div>
        <h3>{"SSL encryption"}</h3>
        <p>
            {
                "In order to protect the security of your data during transmission, this website can only be accessed with SSL encryption via HTTPS."
            }
        </p>
    </div>
);

const BoxChanges = () => (
    <div>
        <h3>{"Changes to my privacy policy"}</h3>
        <p>
            {
                "I reserve the right to adapt this data protection declaration so that it always corresponds to the current legal requirements or to implement changes to my services in the data protection declaration, e.g. when introducing new services. Your renewed visit will then be subject to the new data protection declaration."
            }
        </p>
    </div>
);

const BoxQuestionsComments = ({address}: {address: PostalAddress}) => (
    <div>
        <h3>{"Questions and comments on data protection"}</h3>
        <p>
            {"If you have any questions or comments regarding data protection, please send me an email: "}
            <EmailAddress emailEncrypted={address.emailEncrypted} />
        </p>
    </div>
);

const BoxPersonInCharge = ({address}: {address: PostalAddress}) => (
    <div>
        <h3>{"Person in charge"}</h3>
        <p>
            {
                "Responsible authority in the sense of the data protection laws, in particular the EU General Data Protection Regulation (GDPR), is:"
            }
        </p>
        <address>
            <p>
                {address.name}
                <br />
                {address.street}
                <br />
                {`${address.zip} ${address.city}`}
                <br />
                {address.country}
            </p>
        </address>
        <p>
            {"Email: "}
            <EmailAddress emailEncrypted={address.emailEncrypted} />
        </p>
    </div>
);

const BoxLastUpdated = () => (
    <div>
        <p>{"Last updated: 27 Feb 2021"}</p>
    </div>
);

export default PrivacyPolicyPage;
