import React, {FC} from "react";
import MainNav from "./main-nav";
import PageFooter from "./page-footer";

interface LayoutProps {
    className?: string;
}

const Layout: FC<LayoutProps> = ({children, className}) => (
    <>
        <MainNav />
        <div className="min-h-screen flex flex-col">
            <main className={className}>{children}</main>
            <PageFooter />
        </div>
    </>
);

export default Layout;
