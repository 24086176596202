import React, {FC} from "react";

interface GdprToggleConsentButtonProps {
    textLabels: string[];
    buttonLabels: string[];
    enabled: boolean;
    onToggle: (enabled: boolean) => void;
}

const GdprToggleConsentButton: FC<GdprToggleConsentButtonProps> = ({textLabels, buttonLabels, enabled, onToggle}) => {
    const idx = enabled ? 0 : 1;
    const textLabel = textLabels[idx];
    const buttonLabel = buttonLabels[idx];

    return (
        <span>
            <span>{textLabel}</span>
            <button onClick={() => onToggle(!enabled)} className="btn-primary">
                {buttonLabel}
            </button>
        </span>
    );
};

export default GdprToggleConsentButton;
