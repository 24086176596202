import React, {FC, useState, useEffect} from "react";

interface EmailAddressProps {
    emailEncrypted: string;
}

/**
 * Protect email address from spam bots.
 *
 * - store as base64 string
 * - encrypt like: btoa("test@example.com")
 * - render dynamically after component did mount
 * - render in reverse order and unreverse it with css "direction: rtl"
 * - open mailto link with JavaScript
 */
const EmailAddress: FC<EmailAddressProps> = ({emailEncrypted}) => {
    const [didMount, setDidMount] = useState(false);

    const handleClick = () => openMail(atob(emailEncrypted));

    const render = (value: string) => reverse(atob(value));

    useEffect(() => setDidMount(true));

    return (
        <span
            className=""
            onClick={handleClick.bind(null, emailEncrypted)}
            style={{
                direction: "rtl",
                unicodeBidi: "bidi-override",
            }}
        >
            {didMount && render(emailEncrypted)}
        </span>
    );
};

const openMail = (email: string) => {
    if (window) {
        window.location.href = `mailto:${email}`;
    }
};

const reverse = (str: string) => str.split("").reverse().join("");

export default EmailAddress;
